<template>
  <div class="open">
    <i class="arrow"
       @click="$router.back(-1)"></i>
    <h2>体质说明体质测试</h2>
    <div class="content">
      <div class="info">
        本测试依据《中医体质与分类判定》标准，是我国第一部用于指导和规范中医体质研究及应用的标准规范。用于进行中医体质辨识，以及为中医体质相关疾病的防治、养生保健、生活调理等理论依据。
      </div>
      <div class="tag">
        根据您近一年的体验和感觉选择
      </div>
      <div class="tag"
           style="margin-top:.35rem;">
        说明标准
      </div>
      <div class="standard">
        <div>
          <span>没有：</span>根本没有这种情况
        </div>
        <div>
          <span>一点：</span>感觉上好像有，或感觉不明显
        </div>
        <div>
          <span>有些：</span>状态比较明显，有这个问题
        </div>
        <div>
          <span>相当：</span>状态很明显，已经影响到我了
        </div>
        <div>
          <span>非常：</span>状态非常明显，这个问题一直困扰我
        </div>
      </div>

    </div>
    <div class="start"
         @click="test()">开始体质测试</div>
  </div>
</template>
<script>
import { apiUrl, http } from "../config";
import axios from 'axios';
export default {
  name: 'medicalopen',
  components: {
  },
  data () {
    return {
      name: ''
    }
  },
  mounted () {

  },
  methods: {
    test () {
      let query = this.$route.query;
      this.$router.push({ 'path': '/medicaltest', query })
    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.open {
  width: 7.5rem;
  margin: 0 auto;
  .arrow {
    top: 0.6rem;
  }
  background: url('../assets/img/medical/bg.png');
  background-size: 100% 100%;
  position: relative;
  padding: 0 0.6rem 0.6rem;
  min-height: 100%;
  overflow: hidden;
  h2 {
    font-size: 0.4rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 0.56rem;
    margin-top: 0.5rem;
  }
  .content {
    height: auto;
    padding-bottom: 0.3rem;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 0.08rem 0px rgba(0, 169, 149, 1);
    border-radius: 0.26rem;
    margin-top: 0.84rem;
    padding: 0.7rem 0.65rem;
    .info {
      font-size: 0.22rem;
      font-weight: 400;
      color: rgba(103, 103, 103, 1);
      line-height: 0.46rem;
      text-align: left;
    }
    .tag {
      font-size: 0.24rem;
      font-weight: 500;
      color: rgba(69, 69, 69, 1);
      margin-top: 0.56rem;
      text-align: left;
    }
    .standard {
      div {
        text-align: left;
        font-size: 0.22rem;
        font-weight: 400;
        margin-top: 0.2rem;
        span {
          color: rgba(0, 198, 174, 1);
        }
      }
    }
    .btn {
      width: 2.96rem;
      height: 0.8rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0.02rem 0.17rem 0px rgba(238, 238, 238, 1);
      border-radius: 0.45rem;
      line-height: 0.8rem;
      margin: 0 auto;
      font-size: 0.36rem;
      font-weight: 500;
      color: rgba(0, 198, 174, 1);
      margin-top: 0.7rem;
      &.pause {
        color: rgba(214, 214, 214, 1);
      }
    }
  }
  .start {
    height: 0.88rem;
    margin: 0.8rem 0 1rem 0;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.2rem;
    font-size: 0.4rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 198, 174, 1);
    line-height: 0.88rem;
  }
}
</style>
